import axios from "axios";
import { store } from "../../../state-management/state-mgmt";
import Utility from "../../../shared/utility";
export default {
  stockSavedStatus(call, obj, showMessage) {
    return this.getData("rt/stock_status_saved_run", call, obj, showMessage);
  },
  getStockSavedPrompts(call, userId, projId) {
    return this.getData(`rt/stock_status_saved_prompts?user_id=${userId}&proj_id=${projId}`, call);
  },
  stockStatusReport(call, obj, showMessage) {
    return this.getData("rt/stock_status_report", call, obj, showMessage);
  },
  soShipmentFilter(call, obj, showMessage) {
    return this.getData("/rt/sales_order_shipments", call, obj, showMessage);
  },
  stockStatusPartSearch(call, obj, showMessage) {
    return this.getData("rt/stock_status_part_search", call, obj, showMessage);
  },
  stockWIPDrillData(call, obj, showMessage) {
    return this.getData("rt/stock_wip_drill_data", call, obj, showMessage);
  },
  showPartNumDetailsData(call, obj, showMessage) {
    return this.getData("/co/part_search", call, obj, showMessage);
  },
  salesOrderReceiptReport(call, obj, showMessage) {
    return this.getData("rt/so_receipt_report", call, obj, showMessage);
  },
  partsByLocation(call, obj, showMessage) {
    return this.getData("rt/parts_by_location", call, obj, showMessage);
  },
  purchaseOrderReceipts(call, obj, showMessage) {
    return this.getData("rt/po_receipt_report", call, obj, showMessage);
  },
  purchaseOrderShipment(call, obj, showMessage) {
    return this.getData("rt/purchase_order_shipments", call, obj, showMessage);
  },
  dailySnapshot(call, obj, showMessage) {
    return this.getData("rt/daily_analysis_report", call, obj, showMessage);
  },
  repairDispositionReport(call, obj, showMessage) {
    return this.getData("rt/repair_disposition_report", call, obj, showMessage);
  },
  getWorkCenterList(call, userId, type) {
    return this.getData(`/usb/art_report_getlocs?user_id=${userId}&type=${type}`, call);
  },
  getARTStatusReport(call, userId, type, loc) {
    return this.getData(`/usb/art_report?user_id=${userId}&type=${type}&loc=${loc}`, call);
  },
  getARTStatusData(call, userId) {
    return this.getData(`usb/art_report_gettypes?user_id=${userId}`, call);
  },
  productivityAnalysisReport(call, obj, showMessage) {
    return this.getData("rt/productivity_analysis_report", call, obj, showMessage);
  },
  getPartsDueReportData(call, obj, showMessage) {
    return this.getData("rt/parts_due_report", call, obj, showMessage);
  },
  getOpenSODetailsData(call, obj, showMessage) {
    return this.getData("rt/so_open_orders_report", call, obj, showMessage);
  },
  processThroughputSummary(call, obj, showMessage) {
    return this.getData("rt/process_throughput_summary", call, obj, showMessage);
  },
  openPromptBTOStatusReport(call, userId) {
    return this.getData(`/rt/open_prompt_bto_status_report?user_id=${userId}`, call);
  },
  openBTOStatusReport(projId, buId, call) {
    return this.getData(`/rt/open_bto_status_report?proj_id=${projId}&bu_id=${buId}`, call);
  },
  discrepancyReport(call, obj, showMessage) {
    return this.getData("rt/discrepancy_report", call, obj, showMessage);
  },
  getPickPackBusiness(call, obj, showMessage) {
    return this.getData("rt/pick_pack_ship_details", call, obj, showMessage);
  },
  completedBTOReport(call, obj, showMessage) {
    return this.getData("rt/completed_bto_report", call, obj, showMessage);
  },
  LPNAuditHistoryReport(call, obj, showMessage) {
    return this.getData("rt/lpn_audit_history_report", call, obj, showMessage);
  },
  getPickDetails(call, po_id, so_id) {
    return this.getData(`rt/pick_pack_ship_drill_details?po_id=${po_id}&so_id=${so_id}`, call);
  },
  assetHistoryReportGrid(call, obj, showMessage) {
    return this.getData("rt/asset_history_rpt_load_grid", call, obj, showMessage);
  },
  assetHistoryDetails(call, obj, showMessage) {
    return this.getData("rt/asset_history_report", call, obj, showMessage);
  },
  assetHistoryNewDetails(call, obj, showMessage) {
    return this.getData("rt/asset_history_report_new", call, obj, showMessage);
  },
  assetHistoryVendor(call, po_id) {
    return this.getData(`rt/asset_history_rpt_vendor?po_id=${po_id}`, call);
  },
  assetHistoryCustomer(call, so_id) {
    return this.getData(`rt/asset_history_rpt_customer?so_id=${so_id}`, call);
  },
  assetHistoryPart(call, part_id) {
    return this.getData(`rt/asset_history_rpt_part?part_id=${part_id}`, call);
  },
  assetHistoryWorkCenter(call, loc) {
    return this.getData(`rt/asset_history_rpt_wc?loc=${loc}`, call);
  },
  assetHistoryWarehouse(call, ware_id) {
    return this.getData(`rt/asset_history_rpt_ware?ware_id=${ware_id}`, call);
  },
  assetHistoryBin(call, bin_id) {
    return this.getData(`rt/asset_history_drill_bin?bin_id=${bin_id}`, call);
  },
  assetHistoryWaybillLink(call, sl_code, waybill) {
    return this.getData(`rt/asset_history_rpt_waybill?sl_code=${sl_code}&waybill=${waybill}`, call);
  },
  assetHistoryNoteQuery(call, pass, query_id) {
    return this.getData(`rt/asset_history_drill_query?pass=${pass}&query_id=${query_id}`, call);
  },
  assetHistoryDrillIssues(call, wip_id) {
    return this.getData(`rt/asset_history_drill_issues?wip_id=${wip_id}`, call);
  },
  assetHistoryDrillPickTicket(call, wip_id) {
    return this.getData(`rt/asset_history_drill_pt?wip_id=${wip_id}`, call);
  },
  assetHistoryDrillBTOParts(call, kit_id) {
    return this.getData(`rt/asset_history_drill_bto_parts?kit_id=${kit_id}`, call);
  },
  getFulfillmentProjDrop(call, user_id) {
    return this.getData(`rt/fulfillment_audit_proj_dropdown?user_id=${user_id}`, call);
  },
  getFulfillmentShipperDrop(call, user_id) {
    return this.getData(`rt/fulfillment_audit_shipper_dropdown?user_id=${user_id}`, call);
  },
  getFulfillmentAuditorDrop(call, user_id) {
    return this.getData(`rt/fulfillment_audit_auditor_dropdown?user_id=${user_id}`, call);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res !== undefined) {
      if (res.data.body == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.body.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.body.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.body?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else if (res.data.body.message == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      } else {
        if (!res?.data?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    }
  },
};
